<template>
  <el-drawer
    v-model="isShowEditDrawer"
    :show-close="false"
    modal-class="elv-report-journal-overlay"
    class="elv-report-journal-drawer"
    :before-close="onCheckDrawerStatus"
    :size="400"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-journal-drawer-header__title">
        {{ t('report.Journal') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-report-journal-drawer-header__close"
        @click="onCheckDrawerStatus"
      />
    </template>
    <el-scrollbar v-loading="loading">
      <div class="elv-report-journal-drawer-info">
        <div class="elv-report-journal-drawer-info__title">
          <p class="elv-report-journal-drawer-info__title-journalNo">{{ journalDetail?.journalNo }}</p>
          <div
            v-if="
              !['internal_transfer', 'buy_token', 'sell_token', 'token_trade'].includes(
                journalDetail?.journalType?.slug
              ) &&
              !journalDetail?.isReconciliationAdjustmentJournal &&
              journalDetail?.journalType?.group !== 'GAIN_OR_LOSS_ADJUSTING' &&
              journalDetail?.entryList?.every((entry: any) => !entry?.reconciliationMatch?.reconciliationMatchId)
            "
            class="elv-report-journal-drawer-info__title-operating"
          >
            <div
              v-if="
                (journalDetail.businessDataId === null || journalDetail.businessDataId === '0') &&
                journalDetail.businessDataAutomationRuleId === '0' &&
                journalDetail.gainLossAutomationRuleId === '0' &&
                journalDetail.tradeAutomationRuleId === '0'
              "
              class="edit"
              @click="onEditJournal"
            >
              <SvgIcon name="sources-edit" width="14" height="14" />
              {{ t('button.edit') }}
            </div>
            <SvgIcon name="sources-delete" width="14" height="14" class="delete" @click="onRemoveJournal" />
          </div>
        </div>
        <h3 class="elv-report-journal-drawer-info__journal-type">
          {{ journalDetail?.journalType?.name }}
        </h3>
        <ul class="elv-report-journal-drawer-info-content">
          <li class="elv-report-journal-drawer-info__dateTime">
            <span>{{ t('report.datetime') }}</span>
            <p>
              {{
                dayjs(journalDetail?.datetime)
                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                  .format('YYYY/MM/DD')
              }}
            </p>
          </li>
          <li class="elv-report-journal-drawer-transaction__detail">
            <span>{{ t('report.referenceNo') }}</span>
            <div>
              <p>
                {{ journalDetail?.referenceNo ? formatInputAddress(journalDetail?.referenceNo, 4) : '-' }}
              </p>
            </div>
          </li>
          <li class="elv-report-journal-drawer-info__source">
            <span>{{ t('report.source') }}</span>
            <p>
              <el-tooltip
                v-if="sourceData?.name"
                effect="dark"
                placement="top"
                popper-class="elv-report-table-tips"
                overlay-classname="elv-report-table-tips"
                :show-after="500"
                :disabled="!sourceData?.deletedAt"
              >
                <span
                  :class="{
                    'is-deleted': sourceData?.deletedAt
                  }"
                  >{{ sourceData?.name }}</span
                >
                <template #content> {{ t('common.deleted') }} </template>
              </el-tooltip>
              <template
                v-else-if="
                  [
                    'm2m_assets',
                    'm2m_liabilities',
                    'adjusting_unrealized_crypto_liabilities',
                    'adjusting_unrealized_crypto_assets'
                  ].includes(journalDetail?.journalType?.slug)
                "
                >{{ t('common.valuation') }}</template
              >
              <template v-else>-</template>
            </p>
          </li>
          <li class="elv-report-journal-drawer-info__amount"></li>
        </ul>
        <div v-if="journalDetail?.entryList?.length" class="elv-journal-drawer-compute-preview">
          <p class="elv-journal-drawer-compute-preview-title">{{ t('report.entry') }}</p>
          <ul>
            <li>
              <span class="elv-journal-drawer-compute__direction title" />
              <p
                class="elv-journal-drawer-compute__account title"
                :class="{ 'has-match': entryHasReconciliationMatch }"
              >
                {{ t('common.account') }}
              </p>
              <div class="elv-journal-drawer-compute__debit title">{{ t('common.debit') }}</div>
              <div class="elv-journal-drawer-compute__credit title">{{ t('common.credit') }}</div>
              <p v-if="entryHasReconciliationMatch" class="elv-journal-drawer-compute__match title" />
            </li>
            <li v-for="item in journalDetail?.entryList" :key="item?.journalEntryId">
              <span class="elv-journal-drawer-compute__direction">{{ item?.balanceType }}</span>
              <div class="elv-journal-drawer-compute__account" :class="{ 'has-match': entryHasReconciliationMatch }">
                {{ item?.chartOfAccount?.name }}
                <div v-if="item?.auxiliaryValueList?.length" class="elv-journal-drawer-compute__auxiliary-list">
                  <template v-for="auxiliaryValue in item.auxiliaryValueList" :key="auxiliaryValue.auxiliaryTypeId">
                    <div v-if="getAuxiliaryValue(auxiliaryValue)" class="elv-journal-drawer-compute__account-auxiliary">
                      {{ getAuxiliaryValue(auxiliaryValue) }}
                    </div>
                  </template>
                </div>
              </div>
              <p class="elv-journal-drawer-compute__debit">
                <template v-if="item?.balanceType === 'Dr'">
                  <div v-if="item?.amountFC === null && journalDetail?.entryActionList?.[item?.entryIndex]">
                    <p
                      v-if="
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap.length === 1 &&
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.type === 'AMOUNT_FC' &&
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                        ] !== null
                      "
                      :title="`${fieldValueFormat(
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                        ],
                        {
                          price: true,
                          keepPoint: true,
                          symbol: `${
                            entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                          }`
                        },
                        'NUMBER'
                      )}`"
                    >
                      {{
                        formatNumberToSignificantDigits(
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                            journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                          ],
                          2,
                          `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                        )
                      }}
                    </p>
                    <el-tooltip
                      v-else
                      :show-arrow="true"
                      effect="dark"
                      placement="top"
                      width="190"
                      overlay-classname="elv-report-table-tips"
                      :show-after="500"
                    >
                      <div>
                        <SvgIcon
                          name="function-math"
                          width="18px"
                          height="18px"
                          fill="#1753EB"
                          style="margin-right: -5px"
                        />
                      </div>
                      <template #content>
                        {{
                          formulaFormatter({
                            entry: item,
                            entryAction: journalDetail?.entryActionList?.[item?.entryIndex]
                          })
                        }}
                      </template>
                    </el-tooltip>
                  </div>
                  <p
                    v-else
                    :title="`${fieldValueFormat(
                      item?.amountFC,
                      {
                        price: true,
                        keepPoint: true,
                        symbol: `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      },
                      'NUMBER'
                    )}`"
                  >
                    {{
                      formatNumberToSignificantDigits(
                        item?.amountFC,
                        2,
                        `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      )
                    }}
                  </p>

                  <span
                    v-if="item?.chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'"
                    :title="`${formatNumber(item?.amount, 9)} ${
                      item?.underlyingCurrency?.showSymbol ?? item?.currency
                    }`"
                    >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                    {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-else>-</template>
              </p>
              <p class="elv-journal-drawer-compute__credit">
                <template v-if="item?.balanceType === 'Cr'">
                  <div v-if="item?.amountFC === null && journalDetail?.entryActionList?.[item?.entryIndex]">
                    <p
                      v-if="
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap.length === 1 &&
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.type === 'AMOUNT_FC' &&
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                        ] !== null
                      "
                      :title="`${fieldValueFormat(
                        journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                        ],
                        {
                          price: true,
                          keepPoint: true,
                          symbol: `${
                            entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                          }`
                        },
                        'NUMBER'
                      )}`"
                    >
                      {{
                        formatNumberToSignificantDigits(
                          journalDetail?.entryActionList?.[item?.entryIndex]?.formulaValueMap?.[
                            journalDetail?.entryActionList?.[item?.entryIndex]?.formulaFieldMap[0]?.var
                          ],
                          2,
                          `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
                        )
                      }}
                    </p>
                    <el-tooltip
                      v-else
                      :show-arrow="true"
                      effect="dark"
                      placement="top"
                      overlay-classname="elv-report-table-tips"
                      :show-after="500"
                    >
                      <div>
                        <SvgIcon
                          name="function-math"
                          width="18px"
                          height="18px"
                          fill="#1753EB"
                          style="margin-right: -5px"
                        />
                      </div>
                      <template #content>
                        {{
                          formulaFormatter({
                            entry: item,
                            entryAction: journalDetail?.entryActionList?.[item?.entryIndex]
                          })
                        }}
                      </template>
                    </el-tooltip>
                  </div>
                  <p
                    v-else
                    :title="`${fieldValueFormat(
                      item?.amountFC,
                      {
                        price: true,
                        keepPoint: true,
                        symbol: `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      },
                      'NUMBER'
                    )}`"
                  >
                    {{
                      formatNumberToSignificantDigits(
                        item?.amountFC,
                        2,
                        `${
                          entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
                        }`
                      )
                    }}
                  </p>
                  <span
                    v-if="item?.chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'"
                    :title="`${formatNumber(item?.amount, 9)} ${
                      item?.underlyingCurrency?.showSymbol ?? item?.currency
                    }`"
                    >{{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                    {{ item?.underlyingCurrency?.showSymbol ?? item?.currency }}</span
                  >
                  <span v-else>-</span>
                </template>
                <template v-else>-</template>
              </p>
              <p v-if="entryHasReconciliationMatch" class="elv-journal-drawer-compute__match">
                <el-tooltip
                  v-if="item?.reconciliationMatch?.reconciliationMatchId"
                  effect="dark"
                  placement="top"
                  :show-after="500"
                >
                  <SvgIcon
                    name="Checkmark"
                    width="16"
                    height="16"
                    fill="#41780C"
                    @click="
                      onOpenMatchDetail(
                        item?.reconciliationMatch?.reconciliationTaskId ?? '',
                        item?.reconciliationMatch?.reconciliationMatchId ?? ''
                      )
                    "
                  />
                  <template #content>
                    <div>{{ t('report.matched') }}</div>
                  </template>
                </el-tooltip>
              </p>
            </li>
          </ul>
        </div>
        <div
          v-if="journalDetail?.transactionList?.length || journalDetail?.reconciliationMatchList?.length"
          class="elv-journal-drawer-reconciliation-content"
        >
          <p class="elv-journal-drawer-reconciliation-title">{{ t('report.relatedTransaction') }}</p>
          <div v-if="journalDetail?.transactionList?.length" class="elv-journal-drawer-correlation-content">
            <p class="elv-journal-drawer-correlation-title">{{ t('report.basedOnCategorizing') }}</p>
            <ul>
              <li
                v-if="
                  has(journalDetail?.source, 'deletedAt')
                    ? journalDetail?.source?.deletedAt
                    : props.currentData?.source?.deletedAt
                "
              >
                <div class="elv-journal-drawer-correlation__type">
                  <SvgIcon name="sources-delete" width="16" height="16" fill="#AAAFB6" />
                </div>
                <div class="elv-journal-drawer-correlation__deleted">{{ t('message.transactionDeletedInfo') }}</div>
              </li>
              <template v-else>
                <li v-for="item in journalDetail?.transactionList" :key="item.transactionId">
                  <div
                    class="elv-journal-drawer-correlation__type"
                    :title="`${platformType(item.platformType)} ${t(typeData(item)?.text)}`"
                  >
                    <SvgIcon :name="typeData(item)?.icon" width="16" height="16" />
                  </div>
                  <div class="elv-journal-drawer-correlation__datetime">
                    <el-tooltip
                      :show-arrow="false"
                      effect="dark"
                      placement="top"
                      popper-class="elv-report-table-tips"
                      overlay-classname="elv-report-table-tips"
                      :show-after="500"
                    >
                      <p>
                        {{
                          dayjs(item?.datetime)
                            .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                            .format('YYYY/MM/DD HH:mm')
                        }}
                      </p>
                      <template #content>
                        <div>
                          <p>
                            {{ t('project.originalDatetime')
                            }}{{ calculateUtcOffset(item?.datetime, item?.source?.timezone ?? 'UTC') }}:
                            {{
                              dayjs(item?.datetime)
                                .tz(item?.source?.timezone ?? 'UTC')
                                .format('YYYY/MM/DD HH:mm')
                            }}
                          </p>
                          <p>
                            {{ t('project.utcDatetime') }}:
                            {{ dayjs.tz(item?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                          </p>
                          <p>
                            {{ t('project.reportingDatetime')
                            }}{{
                              calculateUtcOffset(
                                props.currentData?.datetime,
                                entityStore.entityDetail?.timezone ?? 'UTC'
                              )
                            }}:
                            {{
                              dayjs(props.currentData?.datetime)
                                .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                                .format('YYYY/MM/DD HH:mm')
                            }}
                          </p>
                        </div>
                      </template>
                    </el-tooltip>
                    <div
                      v-if="item?.txHash"
                      class="elv-journal-drawer-correlation__datetime-txhash"
                      @click="onClickedCopy(item?.txHash)"
                    >
                      <div
                        class="elv-journal-drawer-correlation__datetime-txhash_text"
                        :data-clipboard-text="item?.txHash"
                      >
                        {{ formatInputAddress(item?.txHash) }}
                      </div>
                      <img
                        src="@/assets/img/copy.png"
                        alt=""
                        class="elv-journal-drawer-correlation__datetime-txhash_copy"
                      />
                    </div>
                  </div>
                  <p
                    class="elv-journal-drawer-correlation__amount"
                    :title="`Base Asset\n${formatNumber(item?.amount, 20)} ${item?.currency}`"
                  >
                    {{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                    <span>{{ item.underlyingCurrency?.showSymbol ?? item?.currency }}</span>
                  </p>
                </li>
              </template>
            </ul>
          </div>
          <div v-if="journalDetail?.reconciliationMatchList?.length" class="elv-journal-drawer-correlation-content">
            <p class="elv-journal-drawer-correlation-title">{{ t('report.basedOnReconciliation') }}</p>
            <ul
              v-if="
                has(journalDetail?.source, 'deletedAt')
                  ? journalDetail?.source?.deletedAt
                  : props.currentData?.source?.deletedAt
              "
            >
              <li>
                <div class="elv-journal-drawer-correlation__type">
                  <SvgIcon name="sources-delete" width="16" height="16" fill="#AAAFB6" />
                </div>
                <div class="elv-journal-drawer-correlation__deleted">{{ t('message.transactionDeletedInfo') }}</div>
              </li>
            </ul>
            <div
              v-for="reconciliationMatch in journalDetail?.reconciliationMatchList"
              v-else
              :key="reconciliationMatch.reconciliationMatchId"
              class="elv-journal-drawer-reconciliation-List"
            >
              <div>
                <p class="elv-journal-drawer-reconciliation-matchNo">{{ reconciliationMatch.matchNo }}</p>
                <ul>
                  <li v-for="item in reconciliationMatch?.transactionList" :key="item.transactionId">
                    <div
                      class="elv-journal-drawer-correlation__type"
                      :title="`${platformType(item.platformType)} ${t(typeData(item)?.text)}`"
                    >
                      <SvgIcon :name="typeData(item)?.icon" width="16" height="16" />
                    </div>
                    <div class="elv-journal-drawer-correlation__datetime">
                      <el-tooltip
                        :show-arrow="false"
                        effect="dark"
                        placement="top"
                        popper-class="elv-report-table-tips"
                        overlay-classname="elv-report-table-tips"
                        :show-after="500"
                      >
                        <p>
                          {{
                            dayjs(item?.datetime)
                              .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                              .format('YYYY/MM/DD HH:mm')
                          }}
                        </p>
                        <template #content>
                          <div>
                            <p>
                              {{ t('project.originalDatetime')
                              }}{{ calculateUtcOffset(item?.datetime, item?.source?.timezone ?? 'UTC') }}:
                              {{
                                dayjs(item?.datetime)
                                  .tz(item?.source?.timezone ?? 'UTC')
                                  .format('YYYY/MM/DD HH:mm')
                              }}
                            </p>
                            <p>
                              {{ t('project.utcDatetime') }}:
                              {{ dayjs.tz(item?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                            </p>
                            <p>
                              {{ t('project.reportingDatetime')
                              }}{{
                                calculateUtcOffset(
                                  props.currentData?.datetime,
                                  entityStore.entityDetail?.timezone ?? 'UTC'
                                )
                              }}:
                              {{
                                dayjs(props.currentData?.datetime)
                                  .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                                  .format('YYYY/MM/DD HH:mm')
                              }}
                            </p>
                          </div>
                        </template>
                      </el-tooltip>
                      <div
                        v-if="item?.txHash"
                        class="elv-journal-drawer-correlation__datetime-txhash"
                        @click="onClickedCopy(item?.txHash)"
                      >
                        <div
                          class="elv-journal-drawer-correlation__datetime-txhash_text"
                          :data-clipboard-text="item?.txHash"
                        >
                          {{ formatInputAddress(item?.txHash) }}
                        </div>
                        <img
                          src="@/assets/img/copy.png"
                          alt=""
                          class="elv-journal-drawer-correlation__datetime-txhash_copy"
                        />
                      </div>
                    </div>
                    <p
                      class="elv-journal-drawer-correlation__amount"
                      :title="`Base Asset\n${formatNumber(item?.amount, 20)} ${item?.currency}`"
                    >
                      {{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                      <span>{{ item.underlyingCurrency?.showSymbol ?? item?.currency }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div v-if="journalDetail?.tradeList?.length" class="elv-journal-drawer-correlation-content">
          <p class="elv-journal-drawer-correlation-title">{{ t('report.relatedTrade') }}</p>
          <ul>
            <li
              v-if="
                has(journalDetail?.source, 'deletedAt')
                  ? journalDetail?.source?.deletedAt
                  : props.currentData?.source?.deletedAt
              "
            >
              <div class="elv-journal-drawer-correlation__type">
                <SvgIcon name="sources-delete" width="16" height="16" fill="#AAAFB6" />
              </div>
              <div class="elv-journal-drawer-correlation__deleted">{{ t('message.transactionDeletedInfo') }}</div>
            </li>
            <template v-else>
              <li v-for="item in journalDetail?.tradeList" :key="item.tradeId">
                <div class="elv-journal-drawer-correlation__type" :title="t(typeTradeData(item)?.text)">
                  <SvgIcon :name="typeTradeData(item)?.icon" width="16" height="16" />
                </div>
                <div class="elv-journal-drawer-correlation__datetime">
                  <el-tooltip
                    :show-arrow="false"
                    effect="dark"
                    placement="top"
                    popper-class="elv-report-table-tips"
                    overlay-classname="elv-report-table-tips"
                    :show-after="500"
                  >
                    <p>
                      {{
                        dayjs(item?.datetime)
                          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                          .format('YYYY/MM/DD HH:mm')
                      }}
                    </p>
                    <template #content>
                      <div>
                        <p>
                          {{ t('project.originalDatetime')
                          }}{{ calculateUtcOffset(item?.datetime, item?.source?.timezone ?? 'UTC') }}:
                          {{
                            dayjs(item?.datetime)
                              .tz(item?.source?.timezone ?? 'UTC')
                              .format('YYYY/MM/DD HH:mm')
                          }}
                        </p>
                        <p>
                          {{ t('project.utcDatetime') }}:
                          {{ dayjs.tz(item?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                        </p>
                        <p>
                          {{ t('project.reportingDatetime')
                          }}{{
                            calculateUtcOffset(
                              props.currentData?.datetime,
                              entityStore.entityDetail?.timezone ?? 'UTC'
                            )
                          }}:
                          {{
                            dayjs(props.currentData?.datetime)
                              .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                              .format('YYYY/MM/DD HH:mm')
                          }}
                        </p>
                      </div>
                    </template>
                  </el-tooltip>
                  <div
                    v-if="item?.tradeHash"
                    class="elv-journal-drawer-correlation__datetime-txhash"
                    @click="onClickedCopy(item?.tradeHash)"
                  >
                    <div
                      class="elv-journal-drawer-correlation__datetime-txhash_text"
                      :data-clipboard-text="item?.tradeHash"
                    >
                      {{ formatInputAddress(item?.tradeHash) }}
                    </div>
                    <img
                      src="@/assets/img/copy.png"
                      alt=""
                      class="elv-journal-drawer-correlation__datetime-txhash_copy"
                    />
                  </div>
                </div>
                <p
                  class="elv-journal-drawer-correlation__amount"
                  :title="`Base Asset\n${formatNumber(item?.baseAmount, 20)} ${assetTypeInfo(item).name}`"
                >
                  {{ formatNumberToSignificantDigits(item?.baseAmount, 2, '', false) }}
                  <span>{{ assetTypeInfo(item).name }}</span>
                </p>
              </li>
            </template>
          </ul>
        </div>

        <div v-if="journalDetail?.gainLossList?.length" class="elv-journal-drawer-correlation-content">
          <p class="elv-journal-drawer-correlation-title">{{ t('report.relatedGainLoss') }}</p>
          <ul>
            <li
              v-if="
                has(journalDetail?.source, 'deletedAt')
                  ? journalDetail?.source?.deletedAt
                  : props.currentData?.source?.deletedAt
              "
            >
              <div class="elv-journal-drawer-correlation__type">
                <SvgIcon name="sources-delete" width="16" height="16" fill="#AAAFB6" />
              </div>
              <div class="elv-journal-drawer-correlation__deleted">{{ t('message.transactionDeletedInfo') }}</div>
            </li>
            <template v-else>
              <li v-for="item in journalDetail?.gainLossList" :key="item.gainLossId">
                <div class="elv-journal-drawer-correlation__type" :title="t(typeGainLossData(item)?.text)">
                  <SvgIcon :name="typeGainLossData(item)?.icon" width="16" height="16" />
                </div>
                <div class="elv-journal-drawer-correlation__datetime">
                  <el-tooltip
                    :show-arrow="false"
                    effect="dark"
                    placement="top"
                    popper-class="elv-report-table-tips"
                    overlay-classname="elv-report-table-tips"
                    :show-after="500"
                  >
                    <p>
                      {{
                        dayjs(item?.datetime)
                          .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                          .format('YYYY/MM/DD HH:mm')
                      }}
                    </p>
                    <template #content>
                      <div>
                        <p>
                          {{ t('project.originalDatetime')
                          }}{{ calculateUtcOffset(item?.datetime, item?.source?.timezone ?? 'UTC') }}:
                          {{
                            dayjs(item?.datetime)
                              .tz(item?.source?.timezone ?? 'UTC')
                              .format('YYYY/MM/DD HH:mm')
                          }}
                        </p>
                        <p>
                          {{ t('project.utcDatetime') }}:
                          {{ dayjs.tz(item?.datetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
                        </p>
                        <p>
                          {{ t('project.reportingDatetime')
                          }}{{
                            calculateUtcOffset(
                              props.currentData?.datetime,
                              entityStore.entityDetail?.timezone ?? 'UTC'
                            )
                          }}:
                          {{
                            dayjs(props.currentData?.datetime)
                              .tz(entityStore.entityDetail?.timezone ?? 'UTC')
                              .format('YYYY/MM/DD HH:mm')
                          }}
                        </p>
                      </div>
                    </template>
                  </el-tooltip>
                  <div
                    v-if="item?.gainLossHash"
                    class="elv-journal-drawer-correlation__datetime-txhash"
                    @click="onClickedCopy(item?.gainLossHash)"
                  >
                    <div
                      class="elv-journal-drawer-correlation__datetime-txhash_text"
                      :data-clipboard-text="item?.gainLossHash"
                    >
                      {{ formatInputAddress(item?.gainLossHash) }}
                    </div>
                    <img
                      src="@/assets/img/copy.png"
                      alt=""
                      class="elv-journal-drawer-correlation__datetime-txhash_copy"
                    />
                  </div>
                </div>
                <p
                  class="elv-journal-drawer-correlation__amount"
                  :title="`Gain(Loss)Asset\n${formatNumber(item?.amount, 20)} ${assetTypeInfo(item).name}`"
                >
                  {{ formatNumberToSignificantDigits(item?.amount, 2, '', false) }}
                  <span>{{ assetTypeInfo(item).name }}</span>
                </p>
              </li>
            </template>
          </ul>
        </div>
      </div>
      <template v-if="journalDetail?.priceList?.length">
        <ul v-for="(item, index) in journalDetail.priceList" :key="index" class="elv-report-journal-drawer-loss">
          <p class="elv-report-journal-drawer-loss__title">{{ t('common.valuation') }}</p>
          <li>
            <p class="elv-report-journal-drawer-loss__label">{{ t('report.priceProvider') }}</p>
            <p class="elv-report-journal-drawer-loss__value">
              {{ providerName(item) }}<span v-if="item?.priceProvider?.isDefault"> ({{ t('common.default') }})</span
              ><span v-if="item?.priceType === 'MANUAL'"> ({{ item?.priceOperator?.name }})</span>
              <span v-if="item?.priceType === 'EXTERNAL_JOURNAL_SOURCE'">
                ({{ journalDetail?.externalJournalSource?.name }})</span
              >
            </p>
          </li>
          <li v-if="journalDetail?.isBrokerInventory && item?.saleNo">
            <p class="elv-report-journal-drawer-loss__label">{{ t('report.salesNo') }}</p>
            <p class="elv-report-journal-drawer-loss__value">
              {{ item?.saleNo }}
            </p>
          </li>
          <li v-else>
            <p class="elv-report-journal-drawer-loss__label">
              {{
                ['EXTERNAL_JOURNAL_SOURCE', 'MANUAL'].includes(item?.priceType)
                  ? t('report.editTimeUTC')
                  : t('report.priceDatetime')
              }}
            </p>
            <p class="elv-report-journal-drawer-loss__value">
              {{ dayjs.tz(item?.priceDatetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
            </p>
          </li>
          <li>
            <p class="elv-report-journal-drawer-loss__label">{{ t('report.pricePair') }}</p>
            <p class="elv-report-journal-drawer-loss__value">
              {{ item?.pricePair }}
            </p>
          </li>
          <li>
            <p class="elv-report-journal-drawer-loss__label">
              {{ t('common.price') }} <template v-if="item.pricePairBase"> ({{ item.pricePairBase }}) </template>
            </p>
            <p class="elv-report-journal-drawer-loss__value">
              {{ formatNumber(Number(item.price), 20) }}
            </p>
          </li>
        </ul>
      </template>

      <ul v-if="journalDetail.cost !== null || journalDetail.gainLoss !== null" class="elv-report-journal-drawer-loss">
        <p class="elv-report-journal-drawer-loss__title">{{ t('report.capitalGainORLoss') }}</p>
        <li v-if="journalDetail.cost !== null">
          <p class="elv-report-journal-drawer-loss__label">{{ t('report.Cost basis') }}</p>
          <p class="elv-report-journal-drawer-loss__value">
            <template v-if="costBasisValue || costBasisValue === 0"> {{ costBasisValue }} </template>
            <template v-else> -</template>
          </p>
        </li>
        <li v-if="journalDetail.gainLoss !== null">
          <p class="elv-report-journal-drawer-loss__label">{{ t('report.capitalGainORLoss') }}</p>
          <p class="elv-report-journal-drawer-loss__value">
            <template v-if="gainLossValue || gainLossValue === 0">{{ gainLossValue }} </template>
            <template v-else> -</template>
          </p>
        </li>
      </ul>
      <Memo ref="uploadMemoRef" :current-data="journalDetail ?? {}" />
    </el-scrollbar>
    <template #footer>
      <elv-button
        :loading="saveLoading"
        :disabled="saveDisabled"
        type="primary"
        height="36px"
        width="100%"
        class="elv-report-journal-drawer-footer__save"
        @click="onSave"
      >
        {{ t('button.saveChanges') }}
      </elv-button>
    </template>

    <ElvMessageBox
      ref="deleteJournalMessageBoxRef"
      :confirmButtonText="t('button.delete')"
      :cancelButtonText="t('button.cancel')"
      :title="t('title.deleteJournal')"
      :loading="deleteJournalLoading"
      class="elv-journal-delete-message-box"
      @onConfirmEvent="onConfirmDeleteJournal"
      @onCancelEvent="onCancelDeleteJournal"
    >
      <template #content>
        <span class="elv-confirm-info-header__title">{{
          t('message.deleteJournalInfo', { journalNo: journalDetail?.journalNo })
        }}</span></template
      >
    </ElvMessageBox>
  </el-drawer>

  <MatchDetailDialog
    ref="matchDetailDialogRef"
    :reconciliationMatchId="reconciliationMatchId"
    :currentData="{ reconciliationTaskId: reconciliationTaskId }"
    @onResetList="updateDetail"
  />
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import Memo from '../../Ledger/Journals/components/Memo.vue'
import {
  formatNumber,
  fieldValueFormat,
  calculateUtcOffset,
  formatInputAddress,
  formatNumberToSignificantDigits
} from '@/lib/utils'
import {
  GainLossItemType,
  TradeItemType,
  JournalPriceType,
  JournalDetailType,
  TransactionItemType
} from '#/TransactionsTypes'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import timezone from 'dayjs/plugin/timezone'
import TransactionsApi from '@/api/TransactionsApi'
import { useEntityStore } from '@/stores/modules/entity'
import { map, has, sortBy, isEqual, flatMap, isEmpty } from 'lodash-es'
import { gainLossType, tradeType, transactionType } from '@/config/index'
import MatchDetailDialog from '@/pages/Financials/Project/components/ReconciliationMatched/MatchDetailDialog.vue'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)
const { toClipboard } = useClipboard()

const uploadMemoRef = useTemplateRef<InstanceType<typeof Memo>>('uploadMemoRef')
const deleteJournalMessageBoxRef = useTemplateRef('deleteJournalMessageBoxRef')
const matchDetailDialogRef = useTemplateRef('matchDetailDialogRef')

const saveLoading = ref(false)
const loading = ref(false)
const isShowEditDrawer = ref(false)
const deleteJournalLoading = ref(false)
const journalDetail = ref({} as JournalDetailType)
const reconciliationTaskId = ref('')
const reconciliationMatchId = ref('')
const emit = defineEmits(['onResetList', 'onEditJournal'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const entryHasReconciliationMatch = computed(() => {
  return journalDetail.value?.entryList?.some((entry: any) => entry?.reconciliationMatch?.reconciliationMatchId)
})

const platformType = computed(() => {
  return (type: string) => {
    let text = ''
    switch (type) {
      case 'CHAIN':
        text = t('common.onChain')
        break
      case 'EXCHANGE':
        text = t('common.exchange')
        break
      case 'BANK':
        text = t('common.bank')
        break
      case 'CUSTODY':
        text = t('common.custody')
        break
      case 'PAYMENT':
        text = t('common.payment')
        break
      default:
        text = ''
        break
    }
    return text
  }
})

const getValidSource = (source: any) => {
  return isEmpty(source) ? null : source
}

const sourceData = computed(() => {
  return (
    getValidSource(journalDetail.value?.businessDataSource) ||
    getValidSource(props.currentData?.businessDataSource) ||
    getValidSource(journalDetail.value?.externalJournalSource) ||
    getValidSource(props.currentData?.externalJournalSource) ||
    getValidSource(journalDetail.value?.source) ||
    getValidSource(props.currentData?.source) ||
    {}
  )
})

const typeData = computed(() => {
  return (row: TransactionItemType) => {
    let data: any = {}
    const transactionTypeList = flatMap(transactionType, 'list')
    transactionTypeList.forEach((item) => {
      if (item.value === row.type) {
        data = {
          icon: item.icon,
          text: item.label
        }
      }
    })
    return data
  }
})

const formulaFormatter = computed(() => {
  return (item: any) => {
    if (item.entry?.amountFC !== null) {
      return ''
    }
    const formula = item?.entryAction?.formulaFieldMap.reduce((acc: any, cur: any) => {
      const reg = new RegExp(cur.var, 'g')
      switch (cur.type) {
        case 'COST_BASIS_OF_SALES':
          return acc.replace(reg, 'Broker inventory.Sales.Cost Basis')
        case 'AMOUNT_FC':
          return cur.value === null
            ? '-'
            : `${formatNumberToSignificantDigits(
                cur.value,
                2,
                `${entityDetail.value?.underlyingCurrency?.sign || `${entityDetail?.value?.underlyingCurrency?.showSymbol} `}`
              )}`
        case 'ENTRY_INDEX':
          return acc.replace(reg, ` #${cur.value + 1} Amount(${defaultCurrency}) `)
        default:
          return acc.replace(reg, ` ${item?.entryAction?.formulaValueMap?.[cur.var]} `)
      }
    }, item?.entryAction?.amountFC)
    // eslint-disable-next-line prefer-const

    return formula ?? ''
  }
})

const typeTradeData = computed(() => {
  return (row: TradeItemType) => {
    let data: any = {}
    tradeType.forEach((item) => {
      if (item.value === row.type) {
        data = {
          icon: item.icon,
          text: item.label
        }
      }
    })
    return data
  }
})

const typeGainLossData = computed(() => {
  return (row: GainLossItemType) => {
    let data: any = {}
    gainLossType.forEach((item) => {
      if (item.value === row.type) {
        data = {
          icon: item.icon,
          text: item.label
        }
      }
    })
    return data
  }
})

const assetTypeInfo = computed(() => {
  return (data: any) => {
    const assetData = { name: '', showSymbol: '' }
    if (isEmpty(data.asset?.underlyingCurrency) && isEmpty(data.asset?.derivative)) {
      return assetData
    }
    if (isEmpty(data.asset?.underlyingCurrency)) {
      assetData.name = data.asset?.derivative?.name || ''
      assetData.showSymbol = data.asset?.derivative?.marginAssetCurrency?.showSymbol || ''
    } else {
      assetData.name = data.asset?.underlyingCurrency?.showSymbol || ''
      assetData.showSymbol = data.asset?.underlyingCurrency?.showSymbol || ''
    }
    return assetData
  }
})

const providerName = (price: JournalPriceType) => {
  switch (price?.priceType) {
    case 'TRADE':
      return 'Trade'
    case 'SALE_COST_BASIS':
      return 'Inventory Cost Basis'
    case 'CUSTOM':
      return 'Customized Price'
    case 'MANUAL':
    case 'EXTERNAL_JOURNAL_SOURCE':
      return 'Manual Set'
    default:
      return price?.priceProvider?.name
  }
}
const getAuxiliaryValue = (item: any) => {
  if (item?.auxiliaryType?.type === 'ENTITY_ACCOUNT') {
    return item?.entityAccount?.name
  }

  if (item?.auxiliaryType?.type === 'COUNTERPARTY') {
    return item?.counterparty?.name
  }

  if (item?.auxiliaryType?.type === 'ITEM') {
    return item.auxiliaryItem?.value
  }

  return null
}

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const costBasisValue = computed(() => {
  return journalDetail.value.cost
    ? formatNumberToSignificantDigits(
        Number(journalDetail.value.cost),
        2,
        `${entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `}`
      )
    : journalDetail.value.cost
})
const gainLossValue = computed(() => {
  return journalDetail.value.gainLoss
    ? formatNumberToSignificantDigits(
        Number(journalDetail.value.gainLoss),
        2,
        `${entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `}`
      )
    : journalDetail.value.gainLoss
})

const saveDisabled = computed(() => {
  const fileList = uploadMemoRef.value?.fileList.map((row: any) => {
    const data = { ...row }
    delete data.uid
    delete data.status
    return data
  })

  const isFileListEqual = isEqual(fileList, journalDetail.value?.journalAttachmentList)
  const isMemoEqual = isEqual(journalDetail.value?.memo, uploadMemoRef.value?.memo)

  return (
    loading.value ||
    (isMemoEqual && isFileListEqual) ||
    (journalDetail.value?.memo === undefined && uploadMemoRef.value?.memo === '' && isFileListEqual)
  )
})

const onCheckDrawerStatus = () => {
  isShowEditDrawer.value = !isShowEditDrawer.value
}

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('message.copiedToClipboard'))
  } catch (e) {
    console.error(e)
  }
}

const onOpenMatchDetail = (taskId: string, matchId: string) => {
  reconciliationMatchId.value = matchId
  reconciliationTaskId.value = taskId
  matchDetailDialogRef.value?.onCheckMatchDetailDialog()
}

const onConfirmDeleteJournal = async () => {
  try {
    deleteJournalLoading.value = true
    await TransactionsApi.deleteJournal(entityId.value, props.currentData?.journalActivityId)
    ElMessage.success(t('message.deleteJournalSuccess'))
    onCheckDrawerStatus()
    emit('onResetList')
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteJournalLoading.value = false
    deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

const onCancelDeleteJournal = () => {
  deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onRemoveJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.delete) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  deleteJournalMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onEditJournal = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  emit('onEditJournal', journalDetail.value)
}

const onSave = async () => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.journal?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }

    saveLoading.value = true
    const params: any = {
      memo: uploadMemoRef.value?.memo
    }
    params.journalAttachmentIds = map(uploadMemoRef.value?.fileList, 'journalAttachmentId')
    await LedgerApi.editJournalActivity(entityId.value, props.currentData?.journalActivityId, params)
    ElMessage.success(t('message.editSuccess'))
    saveLoading.value = false
    onCheckDrawerStatus()
  } catch (error: any) {
    ElMessage.error(error.message)
    saveLoading.value = false
    console.error(error)
  }
}

const fetchJournalDetail = async () => {
  try {
    loading.value = true
    const { data } = await LedgerApi.getJournalDetail(props.currentData?.entityId, props.currentData?.journalActivityId)
    journalDetail.value = data
    const entryList = journalDetail.value?.entryList.map((entry: any, entryIndex: number) => {
      return {
        ...entry,
        entryIndex
      }
    })
    // 这里排序
    journalDetail.value.entryList = journalDetail.value?.entryList?.length
      ? sortBy(entryList, (i: any) => {
          if (i.balanceType === 'Dr') {
            return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 1 : 0
          }
          if (i.balanceType === 'Cr') {
            return i?.chartOfAccount?.slug === 'gst_output' || i?.chartOfAccount?.slug === 'gst_input' ? 3 : 2
          }
          return 0
        })
      : []
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

defineExpose({ onCheckDrawerStatus, fetchJournalDetail })

const updateDetail = async () => {
  await fetchJournalDetail()
  emit('onResetList')
}

watch(
  [() => isShowEditDrawer.value],
  () => {
    if (isShowEditDrawer.value && !isEmpty(props.currentData)) {
      nextTick(async () => {
        if (props.currentData?.journalActivityId) {
          fetchJournalDetail()
        }
      })
    }
  },
  {
    immediate: true,
    flush: 'post'
  }
)
</script>

<style lang="scss" scoped>
.elv-report-journal-drawer {
  .elv-report-journal-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-report-journal-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-journal-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 16px 0px;
    margin-bottom: 16px;

    .el-scrollbar__view {
      padding: 0 24px;
    }
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 24px;
  }

  .elv-report-journal-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
  }

  .elv-report-journal-drawer-info {
    margin-bottom: 16px;

    .elv-report-journal-drawer-info__title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .elv-report-journal-drawer-info__title-journalNo {
        display: flex;
        align-items: center;
        font-family: 'Barlow';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #838d95;
      }

      .elv-report-journal-drawer-info__title-operating {
        display: flex;
        align-items: center;
        justify-content: center;

        .edit {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-right: 8px;
          border-right: 1px solid #edf0f3;
          cursor: pointer;
          margin-right: 8px;

          svg {
            fill: #838d95;
            margin-right: 6px;
          }

          &:hover {
            svg {
              fill: #1e2024;
            }
          }
        }

        .delete {
          fill: #838d95;
          cursor: pointer;

          &:hover {
            fill: #1e2024;
          }
        }
      }
    }

    .elv-report-journal-drawer-info__journal-type {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-size: 24px;
      color: #1e2024;
    }

    .elv-report-journal-drawer-info-content {
      margin: 16px 0;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        height: 54px;
        box-sizing: border-box;
        border-bottom: 1px solid #edf0f3;

        &:nth-child(2n) {
          padding-left: 8px;
          border-left: 1px solid #edf0f3;
        }

        &:nth-child(1),
        &:nth-child(2) {
          height: 60px;
        }

        span {
          font-family: 'Plus Jakarta Sans';
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #838d95;
        }
      }

      .elv-report-journal-drawer-info__source {
        p {
          display: flex;
          align-items: center;
          margin-top: 2px;

          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
            display: block;
          }

          span {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #0e0f11;

            &.is-deleted {
              color: #aaafb6;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-decoration: line-through;
            }
          }
        }
      }

      .elv-report-journal-drawer-transaction__detail {
        > div {
          display: flex;
          align-items: center;
          margin-top: 4px;
        }

        img {
          width: 14px;
          height: 14px;
          display: block;
        }

        p {
          margin-left: 5px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #1e2024;
          margin-right: 4px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-report-journal-drawer-info__dateTime,
      .elv-report-journal-drawer-info__amount {
        p {
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #0e0f11;
          margin-top: 2px;
        }
      }
    }
  }

  .elv-journal-drawer-compute-preview {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;

    .elv-journal-drawer-compute-preview-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    ul {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        min-height: 40px;
        height: fit-content;
        display: flex;
        flex-direction: row;
        align-items: stretch; /* 子元素的高度将与父元素相匹配 */
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          min-height: 28px;
          border-top: 0px;
        }
      }

      .elv-journal-drawer-compute__direction {
        min-height: 40px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }
      }

      .elv-journal-drawer-compute__account {
        width: 156px;
        min-height: 40px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        border-right: 1px solid #edf0f3;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 5px;

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }

        &.has-match {
          width: 129px;
        }

        .elv-journal-drawer-compute__auxiliary-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          width: 100%;
          gap: 4px;
        }

        .elv-journal-drawer-compute__account-auxiliary {
          box-sizing: border-box;
          height: 19px;
          padding: 0px 9px;
          border-radius: 16px;
          max-width: 136px;
          border: 1px solid #dde1e6;
          background: #f9fafb;
          color: #0e0f11;
          font-family: 'Plus Jakarta Sans';
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .elv-journal-drawer-compute__match {
        width: 28px;
        max-width: 28px;
        min-height: 40px;
        flex: 1;
        box-sizing: border-box;
        border-left: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: center;

        &.title {
          min-height: 28px;
        }

        svg {
          cursor: pointer;
        }
      }

      .elv-journal-drawer-compute__debit {
        border-right: 1px solid #edf0f3;
      }

      .elv-journal-drawer-compute__debit,
      .elv-journal-drawer-compute__credit {
        width: 75px;
        min-height: 40px;
        padding-right: 10px;
        flex: 1;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        word-break: break-word;
        text-align: right;

        span {
          color: #838d95;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          white-space: nowrap;
          overflow: hidden;
        }

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 700;
          font-size: 12px;
          line-height: 15px;
          color: #0e0f11;
          min-height: 28px;
        }
      }
    }
  }

  .elv-journal-drawer-reconciliation-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    .elv-journal-drawer-reconciliation-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    .elv-journal-drawer-correlation-content {
      margin-top: 0;

      .elv-journal-drawer-correlation__amount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        span {
          color: #0e0f11;
          font-family: 'Barlow';
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 4px;
        }
      }

      .elv-journal-drawer-correlation-title {
        margin-bottom: 12px;
      }

      .elv-journal-drawer-reconciliation-List {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .elv-journal-drawer-reconciliation-matchNo {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
      }
    }
  }

  .elv-journal-drawer-correlation-content {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f9fafb;
    margin-top: 16px;

    .elv-journal-drawer-correlation-title {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    ul {
      background: #ffffff;
      border: 1px solid #ced7e0;
      border-radius: 4px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #0e0f11;

      li {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-top: 1px solid #edf0f3;

        &:first-child {
          border-top: 0px;
        }
      }

      .elv-journal-drawer-correlation__type {
        height: 100%;
        width: 36px;
        border-right: 1px solid #edf0f3;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .elv-journal-drawer-correlation__datetime {
        width: 151px;
        height: 100%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-right: 1px solid #edf0f3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        .elv-journal-drawer-correlation__datetime-txhash {
          display: flex;
          align-items: center;
          color: #6b7177;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          img {
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: block;
            margin-left: 4px;
          }
        }
      }

      .elv-journal-drawer-correlation__amount {
        width: 151px;
        height: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        overflow: hidden;
        word-break: break-word;
        text-align: right;

        span {
          max-width: 130px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 11px;
          color: #838d95;
          font-weight: 400;
        }
      }

      .elv-journal-drawer-correlation__deleted {
        display: flex;
        padding: 0px 10px;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        width: 301px;
        box-sizing: border-box;
        color: #aaafb6;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .elv-report-journal-drawer-selected {
    width: 100%;
    padding-bottom: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #edf0f3;
    margin-bottom: 16px;

    span {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
    }

    p {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #1e2024;
    }
  }

  .elv-report-journal-drawer-loss {
    box-sizing: border-box;
    padding: 12px 8px;
    width: 100%;
    background: #f9fafb;
    border-radius: 5px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .elv-report-journal-drawer-loss__title {
      width: 100%;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
      padding-bottom: 10px;
      border-bottom: 1px solid #dde1e6;
      margin-bottom: 10px;
    }

    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .elv-report-journal-drawer-loss__label {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .elv-report-journal-drawer-loss__value {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.elv-journal-delete-message-box.el-dialog {
  .el-dialog__body {
    padding: 23px 24px 26px;

    span {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .el-dialog__footer {
    padding-bottom: 20px;
  }
}
</style>
